



.menu-header {
    color: #1ba2d8;
    letter-spacing: 1px;
    padding-left: 0.5rem;
    font-weight: 400;
}

.menu-element {
    padding-left: 0.5rem;
    color: #5b84c4;
}

.menu-element-text {
    padding-left: 0.75rem;
    font-size: 16px;
    margin-bottom: 0px;
}

.active-element {
    color: #f98125 !important;
}

.menu-panel {
    padding-left: 0rem !important;
}
a {
    color: inherit;
    text-decoration: none;
}

a:hover {
    color: #fb9b50;
    text-decoration:none;
    cursor:pointer;
}