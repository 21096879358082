
.user-table-header {
    color: #1ba2d8 !important;
}

.user-table-cell {
    color: #efeeed !important;
    font-size: 12px !important;
}

.table-row {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
}

.table-link {
    color: inherit;
    text-decoration: none;
}

.table-link:hover {
    color: inherit;
    text-decoration:none;
    cursor:pointer;
    background-color: #5b84c4;
}