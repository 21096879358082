
body {
    background-color: #111721;
}


.login-wrapper {
    margin: auto;
    width: 40%;
    padding-top: 2rem !important;
}


.login-header {
    letter-spacing: 5px;
    font-weight: 200;
}