

.card-header {
    color: #1ba2d8;
    font-weight: bold !important;
    background-color: #373e49;
    letter-spacing: 1px;
    border-radius: 0 !important;
}

.card-body {
    background-color: #11141d;
    border-radius: 0;
    color: #1ba2d8 !important;
}

.card {
    border-color: #4c5a72;
    border-radius: 1 !important;
}

.big-text {
    font-weight: bold !important;
    font-size: 5rem;
}
